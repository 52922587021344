

@font-face {
    font-family: 'Lidl';
    src: url('./assets/fonts/LidlFontPro/LidlFontPro-Book.woff2') format('woff2'),
         url('./assets/fonts/LidlFontPro/LidlFontPro-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lidl';
    src: url('./assets/fonts/LidlFontPro/LidlFontPro-Semibold.woff2') format('woff2'),
         url('./assets/fonts/LidlFontPro/LidlFontPro-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Lidl';
    src: url('./assets/fonts/LidlFontPro/LidlFontPro-Bold.woff2') format('woff2'),
         url('./assets/fonts/LidlFontPro/LidlFontPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lidl';
    src: url('./assets/fonts/LidlFontScriptPro/LidlFontScriptPro.woff2') format('woff2'),
         url('./assets/fonts/LidlFontScriptPro/LidlFontScriptPro.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px
);



p  {
    font-size:1.25rem;
}

$gold: #E7C55E;
$blue:#0050AA;
$white: #fff;
$red: #B3122B;
$black: #484848;
$red-gradient: linear-gradient(226.39deg, #AC1129 0%, #EB193A 47.66%, #AC1129 92.8%);
$red-radial-gradient: radial-gradient(54.75% 54.75% at 52.5% 45.25%, #EB193A 0%, #AC1129 100%);

$gold-gradient:  linear-gradient(184.4deg, #AD8B44 6.96%, #D9B76B 51.22%, #A68A4D 91.76%);
$gold-radial-gradient: radial-gradient(50% 50% at 50% 50%, #D9B76B 0%, #A68A4D 100%);


$font-family-sans-serif: "Lidl";
$font-size-base: 1rem;
$line-height-base:            1.3;
$primary: $red;
$secondary: $gold;


$body-bg: $primary;
$body-color: $white;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "info": tomato,
        "danger": teal
);


$lead-font-size:              $font-size-base * 1.15;

$btn-padding-y-lg:            1rem;
$btn-padding-y-sm:            0.7rem;
$btn-focus-width:             0;
$btn-focus-box-shadow:        "none";

$input-color:#000;
$input-bg: $white;
$input-padding-y: .8rem;
$form-check-input-bg: $white;
$form-check-input-border-radius: 50%;
$form-check-input-width:                  1.2em;
$form-check-input-checked-color:          $red;
$form-check-input-checked-bg-color:       $gold;
$form-check-input-border:                 0;
$form-check-label-color:                  $white;
$form-check-radio-checked-bg-image:       none;

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";