@import "custom";
//@import "../node_modules/rfs/scss";

/*
 body {
   overflow: hidden;
 }
 */

html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

:focus {
    outline: none !important;
}

// General


.container > div.row {
  z-index:1;
  position:relative;
}

.container, .container-lg, .container-md, .container-sm {
    //max-width: 100% !important;
}

a,.btn,svg {
  transition: 0.2s;
}


// Headings

.text-gold-gradient {
  background: $gold-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-red-gradient {
  background: $red-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// HR
hr {

  background: $gold-gradient;
  opacity: 1;
  height:3px !important;
}

// Buttons


.btn-gradient-red{
  background: $red-radial-gradient;
  box-shadow: 0px 0px 10px 2px rgba($red, 0.2);
  border-radius: 5px;
  border: 0;

  &:hover {
    background: $red-gradient;
  }
}

.btn-gradient-gold{
  background: $gold-radial-gradient;
  box-shadow: 0px 0px 10px 2px rgba($gold, 0.2);
  border-radius: 5px;
  border: 0;

  &:hover {
    background: $gold-gradient;
  }
}

.btn-light {
    color: #000;
    background-color: #f8f9fa;

    &:hover {
      color: #000;
      background-color: rgba($white, .9);
    }

}

.btn-answer {
    position:relative;
    transition:none;
    &.wrong {
      pointer-events: none;
      background: #4E5761 !important;
      box-shadow: 0px 0px 10px 2px rgba(#4E5761, 0.2);
      &:before {
        position:absolute;
        content: "";
        width: 20px;
        height: 20px;
        left: 15px;
        top:50%;
        transform: translateY(-50%);
        background: url("/assets/img/icons/icon-cross.svg") no-repeat 0 0;
        background-size: 100%;

      }

    }

    &.correct {
      pointer-events: none;
      background: $gold-gradient !important;
      box-shadow: 0px 0px 10px 2px rgba($gold, 0.2);
      &:before {
        position:absolute;
        content: "";
        width: 27px;
        height: 20px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        background: url("/assets/img/icons/icon-check.svg") no-repeat 0 0;
        background-size: 100%;

      }

    }

}

.btn-lg, .btn-group-lg > .btn {
  @include media-breakpoint-down(sm)  {
    font-size:1rem;
  }
}

.was-validated :invalid ~ .invalid-feedback {
    color: $gold;
}

.icon-share, .icon-copy {
  position:relative;
  &:before {
    position:absolute;
    content: "";
    width: 20px;
    height: 20px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-size: 100%;
  } 
}

.icon-share {
  &:before {
    background: url("/assets/img/icons/icon-share.svg") no-repeat 0 0;
  } 
}

.icon-copy {
  &:before {
    background: url("/assets/img/icons/icon-copy.svg") no-repeat 0 0;
  } 
}

// Fonts


.lead {
  font-size:1.5rem;

  @include media-breakpoint-down(sm) {
    font-size:1.1rem;
  }
}

.fw-bolder {
  font-weight:600 !important;
}

.fw-black {
  font-weight:900;
}


// Colors


.white-bg {
  background-color:$white;
}

// Components

.advent-days {
  background: $gold-gradient;
  min-height:450px;

  margin-left: var(--bs-gutter-x, -0.75rem);
  margin-right: var(--bs-gutter-x, -0.75rem);
  padding:30px;
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }


  .day-box {
    opacity: 0;
    display:inline-block;
    width: 25%;


    box-shadow:0 3px 10px rgba(0,0,0,.25);
    margin-bottom: -7px;
    height: 0;
    padding-top: 25%;
    background-color: #ccc;
    position: relative;
    svg, img {
      width:100%;
      height:100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.past {
      //visiblity: hidden;
      //pointer-events: none;
    }

    &.today {
      z-index:9;
      svg path {

        animation: bounce 1s infinite alternate;
        -webkit-animation: bounce 1s infinite alternate;
        animation-timing-function: ease-out;
        &:hover {
          filter: brigtness(1);
        }
      }

    svg > path {

    }

    }

    &.future {
      //pointer-events: none;
      filter: brightness(0.5);
    }





  }
}


@keyframes bounce {
  from {
    transform-origin: center;
    //transform: translateY(0px);
    transform: scale(1);
    opacity:1
    
  }
  to {
    transform-origin: center;
    //transform: translateY(-25px);
    transform: scale(1.1);
    opacity:1;
    box-shadow:0 3px 10px rgba(255,255,255,.3);
    filter: contrast(1.5);
    //fill: $gold;
  }
}



.advent-usp {
  svg {
    width:150px;
    height:150px;
    @include media-breakpoint-down(sm) {
      width:70px;
      height:70px;
    }
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: 0.8rem;
    }
  }
}


// Subscribe component

.subscribe {
  background: $red-radial-gradient;
  -webkit-animation: fadein .4s linear forwards;
  animation: fadein .4s linear forwards;

  .subscribe-form {
    border-left: 1px solid $white;

    @include media-breakpoint-down(md) {
      border-left: 0;
      border-top: none;
      padding-top:10px;
      margin-top:10px;
    }
  }

  &__fadeout {
    -webkit-animation: fadeout .4s linear forwards;
    animation: fadeout .4s linear forwards;
  }

}

@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
    
@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}


.form-check-input {
  cursor: pointer;
}

.form-check-input[type="radio"] {
    width:40px;
    height:40px;
}

.radio-inside {
  
  .form-check-label {
    cursor: pointer;
    color: #000;
    position:absolute;
    left:50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    line-height:1;
  }
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: $white;
}
.invalid-feedback {
  color: $white;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: $white;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .form-control:valid:focus, .form-control.is-valid:focus  {
border-color: $white;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background: $gold-gradient;
    color: $black;
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: $white;
}
.was-validated .radio-inside .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: $black;
}
.form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: $gold;
    outline: 0;
    box-shadow: none;
}

.form-check-input[type="radio"]:checked {
    //background-image: inherit;
    background-image: $gold-gradient !important;
    border-color: $white;
}

.redInput {
  background: $red-gradient;
  color: $white;
  border-radius:5px;
  &:focus {
    color: $white;
  }
}


// Quiz component

.question-section {
  img {
    max-width: 525px;
  }

  &.puzzleword {
    position:relative;
    p {
      white-space: pre-line;
      width: 70%;
      margin: 0 auto;
      position:relative;
      background: $red-gradient;
      color: $white;
      padding:40px 40px 60px 40px;
      z-index:2;
      border-radius: 5px;
      @include media-breakpoint-down(sm) {
        width: 90%;
        padding: 20px 20px 40px 20px;
      }
    }

    img {
      margin-top:-50px;
    }

    .video {
      z-index: 2;
      position:relative;
      max-width: 560px;
      height: auto;
      margin: 0 auto;
      margin-top:-20px;
    }
  }
  
}















.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  left: 0;
  right: 0;
}



.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.confetti  {

    position: absolute !important;
    z-index: 3;
    left: 50%;
    bottom: 0;
    width:500px;
    perspective: 500px
 

}

// Tooltip
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: $primary;
    border-radius: 0.25rem;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-top-color: $red;
}



//Component Memory




.deck {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  perspective: 1000px;
}

.card {
  position: relative;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s;
  user-select: none;
  transform-style: preserve-3d;
  margin: 0 0 1% 0;
  width: 24%;
  padding-top: 24%;
  border: 0;
  background-color: transparent;

  @include media-breakpoint-down(sm) {
    margin: 0 0 1% 0;
    width: 32.33%;
    padding-top: 33.33%;
  }
}

:focus {
  outline: 1px hotpink dotted;
}

.card div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: .6s;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}
.card p {
  margin: 0;
  padding:0;
}
.card img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}
.card .front {
  background-color: #ccc;
  color: #000;
}
.card .back {

  background: url("/assets/img/smrekca.svg"), $red-radial-gradient; 
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;

  cursor: pointer;
  color: #ccc;
}
.card .front, 
.card.flipping,
.card.matched {
  transform: rotateY(180deg);
}
.card.matched .front {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
  animation-fill-mode: both;
  opacity: .3;
}

.options {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}
.options .card {
  opacity: .4;
  filter: grayscale(100%);
}
.options .card.selected {
  opacity: 1;
  filter: none;
}

.errors {
  color: #666;
}

.finish {
  background-position: center;
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finish .content {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  position: relative;
  color: #666;
  z-index: 1;
  border: 1px solid #000;
  box-shadow: inset 0 0 6px 2px rgba(0,0,0,.5);
}

.finish h1 {
  font-size: 1.6em;
}

.finish .content:before {
  content: "";
  position: absolute; 
  top: 0; left: 0;
  right: 0; bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,.6);
  filter: blur(2px);
  z-index: -1;
}
.finish nav {
  justify-content: center;
  flex-wrap: wrap;
}
.finish nav button, 
.finish nav .button {
  margin: 0 20px 10px;
}

.fadein {
  animation: fadein .3s;
}

@keyframes fadein {
  from { opacity: 0.5; }
  to   { opacity: 1; }
}

/* Responsive */
@media all and (min-width: 480px) {
  .finish nav {
    flex-wrap: initial;
  }
  .finish nav button, 
  .finish nav .button {
    margin: 0 20px;
  }
  .card {

  } 
}



.puzzle-container {
  @include media-breakpoint-down(md) {
    zoom: 0.7;
  }

  @media all and (max-width: 435px) {
    zoom:0.58;
  }

  .piece > div {
    border: 0 !important ;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .15);
  }

  &.true {
    .piece > div {
      border: 0 !important;
      box-shadow: none !important;
      pointer-events: none;
    }
  }

  
}



// DROPZONE
.dropzone {
  flex: 1;
  height: 800px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.dropzone.left {
  margin-right: 10px;
}

.grid-item {
  cursor: pointer;
  padding:0;
  margin:0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.grid-item-content {
  width: 271px;
  height: 271px;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
}


// Click and find component

.imageClickAndFind {
  background: $gold-gradient;
  padding:10px;
  position:relative;


  @include media-breakpoint-down(md) {
    padding:10px;
  }

  .cross {
    width:30px;
    height:30px;
    position:absolute;
    z-index: 999;

    &.x-hidden {
      opacity: 0;
      transition: all 250ms linear .5s; // <- the last value defines transition-delay
    }

  }

  .item {
    position: absolute;
    opacity:0;
    z-index: 999;
    cursor:pointer;
    width: 10%;
    height: 8%;
    img {
      width:100%;
      height:100%;
    }

    &.found {
      pointer-events: none;
      opacity: 1;
    }
  }

}

.imageClickAndFind {display: inline-block;position: relative;}
.imageClickAndFind img { cursor: pointer;width:100%;}
/*
.imageClickAndFind .item1 {top: 4%;right: 87%;width: 10%;height: 8%;}
.imageClickAndFind .item2 {top: 82%; right: 3%;width: 10%;height: 8%;}
.imageClickAndFind .item3 {top: 60%;right: 50%;width: 10%;height: 8%;}
*/





//Component Prizes


.prizes {
  position:relative;
  .close {
    position:absolute;
    z-index: 2;
    right:20px;
    top:20px;
    cursor:pointer;
    @include media-breakpoint-down(sm) {
      width:50px;
    }
  }
}


.home {
  position:relative;
  .close {
    position:absolute;
    z-index: 2;
    right:0;
    top:0;
    cursor:pointer;
    @include media-breakpoint-down(sm) {
      width:50px;
    }
  }
}



.icon-nagrade, .prizes {

  &:hover {

    filter: drop-shadow(3px 3px 10px rgba(135, 0, 0, 0.5));
  }

}

.icon-nakoledar {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width:70px;
  @include media-breakpoint-down(sm) {
    width:60px;
  }

}

.header-actions {
  .icon-nagrade {

    position:relative;
    z-index: 2;
    cursor:pointer;
    width:80px;
    @include media-breakpoint-down(sm) {
      width:50px;
    }
  }

  .icon-sound {
    position:relative;
    float:right;
    z-index: 2;
    cursor:pointer;
    width:80px;
    @include media-breakpoint-down(sm) {
      width:50px;
    }

  }
}










.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}



// Okraski
.bg-dec {
  position:absolute;
  z-index:0;
  &__topleft {
    left:0;
    top:0;
    @include media-breakpoint-down(md) {
      display:none;
    }

  }
  &__topright {
    right:0;
    top:0;
    @include media-breakpoint-down(md) {
      display:none;
    }
  }
  &__bottomleft {
    left:0;
    bottom:0;
    @include media-breakpoint-down(md) {
      display:none;
    }
  }
  &__bottomright {
    right:0;
    bottom:0;
    @include media-breakpoint-down(md) {
      display:none;
    }
  }

  &__mobile {
    top:0;
    left:0;
    width:100%;

    @include media-breakpoint-up(md) {
      display:none;
    }

  }
}


// Youtube embed
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


.toggle-sound {
  position:fixed;
  bottom:20px;
  right: 20px;
  z-index:10;
}

.popover-body {
  button {
    margin: 0 10px;
  }
}

